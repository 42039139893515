















































































































































































































































h2, .results-text {
  color: var(--color-text-primary);
}

.table-new-ipos{
    width: 90%;
    height: 100%;
    margin: auto;
}

.table-custom{
    width: 100%;
}
.entire-table{
    width: 100%;
}

.filters-container{
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
  margin-left: 100px;
}

.filters-container-mobile{
  margin-bottom: 10px;
  margin-left: 20px;
}

.news-ipos-input{
  width: 20%;
  margin-right: 30px;
  margin-top: 5px;
}

.news-ipos-input-mobile{
  width: 40%;
  margin-right: 30px;
  margin-top: 5px;
}

.news-ipos-select{
  width: 20%;
  margin-right: 30px;
  margin-top: 5px;
}

.news-ipos-select-mobile{
  width: 40%;
  margin-right: 30px;
  margin-top: 5px;
}

.filters-text{
  color: #bdbaba;
  font-weight: bold;
}

.search-button{
  margin-top:13px;
}
.results-text{
  font-weight: bold;
  font-size: 28px;
  margin-left: 25px;
}

#new-ipos{
  overflow:hidden;
}

.next-ipos-description-container{
  display: -webkit-box;
  width: 80%;
  margin-left: 90px;
  font-size: 12px;
  color: #31708f;
  border: 1px solid #bce8f1;
  border-radius: 15px;
  background: #d9edf7;
  margin-bottom: 20px;
}

.info-icon{
  margin-top: 8px;
  margin-right: 10px;
  margin-left: 10px;
}

@media screen and (max-width: 400px){
  .next-ipos-description-container{
    width: 80% !important;
    margin-left: 20px !important;
  }
  .next-ipos-description-text{
    width: 80% !important;
  }
}

.results-ipos-container{
  display: flex;
  margin-left: 100px;
  margin-top:30px;
  margin-bottom: 50px;
}

.results-ipos-container-mobile{
  display: flex;
  margin-left: 20px;
  margin-top:30px;
  margin-bottom: 50px;
}

.container-image{
    /* Aplica un margen automático para centrar el div horizontalmente */
    margin: 0 auto;
    /* Ajusta el ancho máximo del contenedor */
    max-width: 800px;
    max-height: auto;
    
  }



  .container-image img{
    max-width: 500px;   
    max-height: 800px;
    margin: 20px;
    border-radius: 5px;
  }

  @media screen and (min-width: 768px) {
    .container-image img {
      width: 500px;
    }
  }

  .text-center {
    text-align: center;
    margin: 20px;
  }

  .container-image p {    
    text-align: justify;
  }

  .login-button{
    color: white;
    background-color: #00add8;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    border: none;
    outline: none;
    width: 150px;
    margin-top: 20px;
    font-size: 14px;
  }
  .login-button:hover{
    background-color: #00bdec;
  }

  .not-allowed-new-ipos-container{
    text-align: center;
    margin-top: 50px;
  }
  
  .link-to-store{
    color: #35add8 !important;
  }

  .not-allowed-new-ipos-title{
    color: #FF9B00;
    font-weight: bold;
  }

